<template>
  <div class="start-page">
    <Header />
    <main>
      <h1>Medica24 Planungs App</h1>
      <div class="menu-buttons">
        <button v-if="isLoggedIn && hasRole(['admin'])" @click="navigateTo('/shift-overview')">Übersicht Schichten</button>
        <button v-if="isLoggedIn && hasRole(['hospital', 'admin'])" @click="navigateTo('/hospital')">Spital Übersicht</button>
        <button v-if="isLoggedIn && hasRole(['hospital', 'admin'])" @click="navigateTo('/hospital/new-shift')">Spital Neue Schicht</button>
        <button v-if="isLoggedIn && hasRole(['worker'])" @click="navigateTo('/worker/overview')">Arbeitnehmer Übersicht</button>
        <button v-if="!isLoggedIn" @click="navigateTo('/login')">Login</button>
        <button v-if="isLoggedIn && hasRole(['admin'])" @click="navigateTo('/admin')">Administration Spital & User</button>
        <button v-if="isLoggedIn" @click="navigateTo('/account')">Account</button>
        <button v-if="isLoggedIn" @click="navigateTo('/logout')">Logout</button>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';

const store = useStore();
const router = useRouter();

const user = computed(() => store.state.user);
const isLoggedIn = computed(() => store.getters.isAuthenticated);

const navigateTo = (path) => {
  router.push(path);
};

const hasRole = (accessRoles) => {
  return user.value && accessRoles.includes(user.value.accessRole);
};
</script>

<style scoped>
.start-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

main {
  flex: 1;
  width: 100%; /* Ensure main takes full width */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding: 1rem;
  box-sizing: border-box;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%; /* Ensure buttons container takes full width */
  max-width: 600px; /* Set a maximum width for the container */
}

.menu-buttons button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 100%; /* Make buttons take full width of the container */
}
</style>
