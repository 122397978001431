<template>
  <div class="worker-page">
    <Header />
    <main>
      <div v-if="showPhoneNotification" class="phone-notification">
        <div class="notification-content">
          <span>Für SMS Benachrichtigungen Mobile-Nr. hinzufügen</span>
          <button @click="$router.push('/account')" class="notification-button">Zum Profil</button>
        </div>
      </div>
      <div class="content-container">
        <h1>Arbeitnehmer Übersicht</h1>
        <h2>Offene Schicht-Anfragen</h2>
        <div v-if="openShifts.length === 0" class="no-shifts-message">
          Keine offenen Schicht Anfragen
        </div>
        <div v-else class="shift-table">
          <div v-for="shift in openShifts" :key="shift._id" class="table-row">
            <div class="table-cell" data-label="Datum & Zeit">
              <div><b>Start:</b> {{ formatDate(shift.start) }}</div>
              <div><b>Ende:</b> {{ formatDate(shift.end) }}</div>
            </div>
            <div class="table-cell" data-label="Ort">{{ shift.hospital?.name || 'N/A' }}</div>
            <div class="table-cell" data-label="Kommentar">{{ shift.comment }}</div>
            <div class="table-cell edit-cell" data-label="Antworten">
              <button @click="openConfirmDialog(shift)" class="confirm-button">Annehmen</button>
            </div>
          </div>
        </div>
        <h2>Zukünftige Bestätigte Schichten</h2>
        <div class="shift-table-container">
          <div class="shift-table">
            <div v-for="shift in paginatedShifts" :key="shift._id" class="table-row">
              <div class="table-cell" data-label="Start Datum">{{ formatDate(shift.start) }}</div>
              <div class="table-cell" data-label="Ende Datum">{{ formatDate(shift.end) }}</div>
              <div class="table-cell" data-label="Status">{{ shift.status }}</div>
              <div class="table-cell" data-label="Ort">{{ shift.hospital?.name || 'N/A' }}</div>
              <div class="table-cell" data-label="Kommentar">{{ shift.comment }}</div>
            </div>
          </div>
        </div>
        <div v-if="confirmedShifts.length > 20" class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">Vorige</button>
          <span>Page {{ currentPage }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">Nächste</button>
        </div>
      </div>
    </main>
    <Footer />

    <!-- Confirm Dialog -->
    <div v-if="isDialogOpen" class="modal">
      <div class="modal-content">
        <h2>Schicht annehmen?</h2>
        <p><strong>Start:</strong> {{ formatDate(selectedShift.start) }}</p>
        <p><strong>Ende:</strong> {{ formatDate(selectedShift.end) }}</p>
        <p><strong>Ort:</strong> {{ selectedShift.hospital.name }}</p>
        <div class="modal-actions">
          <button @click="confirmShift" class="confirm-button">Schicht verbindlich annehmen</button>
          <button @click="closeDialog" class="cancel-button">Abbrechen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import api from '../axios';
import { useStore } from 'vuex';

const store = useStore();
const showPhoneNotification = ref(false);
const openShifts = ref([]);
const confirmedShifts = ref([]);
const isDialogOpen = ref(false);
const selectedShift = ref({});
const currentPage = ref(1);
const shiftsPerPage = 5;

const formatDate = (date) => {
  const options = {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Zurich'
  };
  return new Date(date).toLocaleString('de-DE', options) + ' Uhr';
};

const fetchOpenShifts = async () => {
  try {
    const response = await api.get('/shifts/open');
    openShifts.value = response.data.sort((a, b) => new Date(a.start) - new Date(b.start));
  } catch (error) {
    console.error('Error fetching open shifts:', error);
  }
};

const fetchConfirmedShifts = async () => {
  try {
    const response = await api.get('/shifts/confirmed');
    confirmedShifts.value = response.data.sort((a, b) => new Date(a.start) - new Date(b.start));
  } catch (error) {
    console.error('Error fetching confirmed shifts:', error);
  }
};

const openConfirmDialog = (shift) => {
  selectedShift.value = shift;
  isDialogOpen.value = true;
};

const closeDialog = () => {
  isDialogOpen.value = false;
  selectedShift.value = {};
};

const confirmShift = async () => {
  try {
    await api.post('/shifts/accept', { shiftId: selectedShift.value._id });

    // Move the confirmed shift from openShifts to confirmedShifts
    const shiftIndex = openShifts.value.findIndex(shift => shift._id === selectedShift.value._id);
    if (shiftIndex !== -1) {
      const confirmedShift = openShifts.value.splice(shiftIndex, 1)[0];
      confirmedShift.status = 'Confirmed'; // Assuming you want to set a status
      confirmedShifts.value.push(confirmedShift);
      confirmedShifts.value.sort((a, b) => new Date(a.start) - new Date(b.start));
    }

    closeDialog();
  } catch (error) {
    console.error('Error accepting shift:', error);
  }
};

const paginatedShifts = computed(() => {
  const now = new Date();
  const futureShifts = confirmedShifts.value.filter(shift => new Date(shift.end) > now);
  const start = (currentPage.value - 1) * shiftsPerPage;
  const end = start + shiftsPerPage;
  return futureShifts.slice(start, end);
});

const totalPages = computed(() => {
  const now = new Date();
  const futureShifts = confirmedShifts.value.filter(shift => new Date(shift.end) > now);
  return Math.ceil(futureShifts.length / shiftsPerPage);
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

onMounted(() => {
  fetchOpenShifts();
  fetchConfirmedShifts();

  const user = store.getters.user;
  if (user && !user.phone && !user.hasSeenPhoneNotification) {
    showPhoneNotification.value = true;
    store.dispatch('updatePhoneNotificationSeen');
  }
});
</script>

<style scoped>
.phone-notification {
  background-color: #007aff;
  color: white;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  max-width: 600px;
  border-radius: 8px;
}

.notification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.notification-button {
  background-color: white;
  color: #007aff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.notification-button:hover {
  background-color: #f0f0f0;
}

.worker-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  /* Center the content horizontally */
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.content-container {
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0 auto; /* Center the container horizontally */
}

h1,
h2 {
  text-align: center;
}

.no-shifts-message {
  text-align: center; /* Center text horizontally */
  padding: 2rem;
  color: #666;
  width: 100%;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  min-height: 100px; /* Ensure a minimum height */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  margin: 0; /* Remove any default margin */
}

.shift-table-container {
  overflow-x: hidden;
  width: 100%;
}

.shift-table {
  display: block;
  width: 100%;
}

.table-row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
}

.table-cell {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  position: relative;
  padding-left: 120px;
  text-align: left;
}


.table-cell[data-label="Kommentar"] {
  min-height: 2rem;
  /* Set a minimum height */
}

.table-cell::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  width: 100px;
  font-weight: bold;
  white-space: nowrap;
}

.edit-cell {
  text-align: left;
  padding-right: 1rem;
}

.confirm-button {
  padding: 0.5rem 1rem;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.confirm-button:hover {
  background-color: #005bb5;
}

.cancel-button {
  padding: 0.5rem 1rem;
  background-color: #6c757d;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.cancel-button:hover {
  background-color: #999;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 0 1rem;
  max-width: 500px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
}
</style>
