<template>
  <header>
    <nav>
      <div class="menu" @click="toggleMenu">☰</div>
      <div :class="{ 'nav-open': isMenuOpen }" class="nav-links" ref="navLinks">
        <ul>
          <li v-if="isAuthenticated && hasRole(['admin'])" @click="closeMenu"><router-link to="/shift-overview">Übersicht Schichten</router-link></li>
          <li v-if="isAuthenticated && hasRole(['hospital', 'admin'])" @click="closeMenu"><router-link to="/hospital">Spital Übersicht</router-link></li>
          <li v-if="isAuthenticated && hasRole(['hospital', 'admin'])" @click="closeMenu"><router-link to="/hospital/new-shift">Spital Neue Schicht</router-link></li>
          <li v-if="isAuthenticated && hasRole(['worker'])" @click="closeMenu"><router-link to="/worker/overview">Arbeitnehmer Übersicht</router-link></li>
          <li v-if="!isAuthenticated" @click="closeMenu"><router-link to="/login">Login</router-link></li>
          <li v-if="isAuthenticated && hasRole(['admin'])" @click="closeMenu"><router-link to="/admin">Administartion Spital & User</router-link></li>
          <li v-if="isAuthenticated" @click="closeMenu"><router-link to="/account">Account</router-link></li>
          <li v-if="isAuthenticated" @click="closeMenu"><router-link to="/logout">Logout</router-link></li>
        </ul>
      </div>
      <div class="logo"><router-link to="/" class="logo-text">Medica24</router-link></div>
    </nav>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useStore } from 'vuex';

const isMenuOpen = ref(false);
const navLinks = ref(null);

const store = useStore();
const user = computed(() => store.state.user);
const isAuthenticated = computed(() => store.getters.isAuthenticated);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

const handleClickOutside = (event) => {
  if (navLinks.value && !navLinks.value.contains(event.target) && !event.target.closest('.menu')) {
    closeMenu();
  }
};

const hasRole = (accessRole) => {
  return user.value && accessRole.includes(user.value.accessRole);
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: white;
  position: sticky;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0.1em;
  height: 50px;
}

.menu {
  cursor: pointer;
  display: none;
  position: absolute;
  left: 1rem;
}

.logo {
  position: absolute;
  right: 1rem;
  padding-right: 10px;
}

.nav-links {
  display: flex;
  justify-content: top;
  transition: transform 0.3s ease-in-out;
}

.nav-links ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 1rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #ffcc00;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info span {
  margin-right: 1rem;
}

button {
  background-color: #555;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

button:hover {
  background-color: #777;
}

@media (max-width: 768px) {
  .menu {
    display: block;
  }

  .logo {
    margin-left: auto;
  }

  .nav-links {
    position: fixed;
    top: 55px;
    left: 0;
    height: calc(100% - 60px);
    max-height: 80vh;
    width: 250px;
    background-color: #333;
    flex-direction: column;
    align-items: center;
    transform: translateX(-100%);
    visibility: hidden;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out, visibility 0s 0.3s;
  }

  .nav-links.nav-open {
    transform: translateX(0);
    visibility: visible;
    transition: transform 0.3s ease-in-out, visibility 0s;
  }

  .nav-links ul {
    flex-direction: column;
    margin-top: 1rem;
  }

  .nav-links li {
    margin: 1rem 0;
  }
}
</style>
