<template>
  <div class="hospital-page">
    <Header />
    <main>
      <div class="top-bar">
        <HospitalSelector v-if="user.accessRole === 'hospital' || user.accessRole === 'admin'" @change="fetchShifts" />
        <button @click="toggleCalendar" class="calendar-icon-button"
          :title="showCalendar ? 'Kalenderansicht verbergen' : 'Kalenderansicht anzeigen'">
          <i :class="showCalendar ? 'fas fa-calendar-times' : 'fas fa-calendar'"></i>
          <span v-if="showInitialHint" class="hint-message">{{ showCalendar ? 'Kalenderansicht verbergen' :
            'Kalenderansicht anzeigen' }}</span>
        </button>
      </div>
      <h1>Spital Übersicht</h1>
      <button @click="navigateToNewShift" class="new-shift-button">Neue Schicht Ausschreiben</button>
      <div v-show="showCalendar" class="calendar-container">
        <FullCalendarOverview :events="calendarEvents" />
      </div>
      <h2>Übersicht Schichten</h2>
      <div class="filters">
        <label>
          Von:
          <input type="date" v-model="startDate" @change="fetchShifts" />
        </label>
        <label>
          Bis:
          <input type="date" v-model="endDate" @change="fetchShifts" />
        </label>
        <input type="text" v-model="searchQuery" placeholder="Search..." />
      </div>
      <div class="shift-table-container">
        <table class="shift-table">
          <thead>
            <tr>
              <th @click="sortBy('status')" class="sortable">
                Status
                <i :class="getSortIcon('status')"></i>
              </th>
              <th @click="sortBy('userName')" class="sortable">
                Name Vorname
                <i :class="getSortIcon('userName')"></i>
              </th>
              <th @click="sortBy('types')" class="sortable">
                Funktionen
                <i :class="getSortIcon('types')"></i>
              </th>
              <th @click="sortBy('start')" class="sortable">
                Start Datum & Zeit
                <i :class="getSortIcon('start')"></i>
              </th>
              <th @click="sortBy('end')" class="sortable">
                Ende Datum & Zeit
                <i :class="getSortIcon('end')"></i>
              </th>
              <th>Schicht löschen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="shift in sortedShifts" :key="shift._id">
              <td>
                <span :class="`status-dot ${shift.status.toLowerCase()}`"></span>
                {{ shift.status }}
              </td>
              <td>{{ shift.user ? shift.user.name : 'N/A' }}</td>
              <td>{{ shift.types.join(', ') }}</td>
              <td>{{ formatDateTime(shift.start) }}</td>
              <td>{{ formatDateTime(shift.end) }}</td>
              <td>
                <button v-if="shift.status === 'planned' || shift.status === 'confirmed'"
                  @click="handleDeleteClick(shift)"
                  :class="['action-button', canDeleteShift(shift) ? 'delete-button' : 'disabled-button']"
                  :title="getDeleteButtonTitle(shift)">
                  ✕
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Confirmation Modal -->
      <div v-if="showDeleteModal" class="modal">
        <div class="modal-content">
          <h3>Bestätigung</h3>
          <p>Möchten Sie diese Schicht wirklich löschen?</p>
          <div class="modal-actions">
            <button @click="confirmDelete">Ja</button>
            <button @click="showDeleteModal = false">Nein</button>
          </div>
        </div>
      </div>

      <!-- Info modal -->
      <div v-if="showInfoModal" class="modal">
        <div class="modal-content">
          <h3>Information</h3>
          <p>{{ infoMessage }}</p>
          <div class="modal-actions">
            <button @click="showInfoModal = false">OK</button>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import FullCalendarOverview from '../components/FullCalendarOverview.vue';
import HospitalSelector from '../components/HospitalSelector.vue';
import api from '../axios';

const shifts = ref([]);
const showCalendar = ref(true);
const searchQuery = ref('');
const startDate = ref(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10));
const endDate = ref(new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().substr(0, 10));
const currentSort = ref('start');
const currentSortDir = ref('asc');
const showInitialHint = ref(true);
const store = useStore();
const user = computed(() => store.getters.user);
const selectedHospital = computed(() => store.getters.selectedHospital);
const router = useRouter();
const showDeleteModal = ref(false);
const infoMessage = ref('');
const shiftToDelete = ref(null);
const canDeleteShift = (shift) => {
  return shift.status === 'planned';
};
const showInfoModal = ref(false);

const getDeleteButtonTitle = (shift) => {
  if (shift.status === 'confirmed') {
    return 'Bestätigte Schichten können nicht gelöscht werden';
  }
  return canDeleteShift(shift) ? '' : 'Schicht kann nicht mehr gelöscht werden';
};

const handleDeleteClick = (shift) => {
  if (shift.status === 'confirmed') {
    infoMessage.value = 'Bestätigte Schichten können nicht gelöscht werden';
    showInfoModal.value = true;
  } else if (canDeleteShift(shift)) {
    shiftToDelete.value = shift;
    showDeleteModal.value = true;
  } else {
    infoMessage.value = 'Diese Schicht kann nicht mehr gelöscht werden, da die Frist abgelaufen ist';
    showInfoModal.value = true;
  }
};

const confirmDelete = async () => {
  try {
    await api.delete(`/shifts/${shiftToDelete.value._id}`);
    await fetchShifts();
    showDeleteModal.value = false;
    shiftToDelete.value = null;
  } catch (error) {
    console.error('Error deleting shift:', error);
    alert('Fehler beim Löschen der Schicht');
  }
};

const fetchShifts = async () => {
  if (!selectedHospital.value) {
    return;
  }

  try {
    const response = await api.get(`/shifts`, {
      params: {
        hospitalId: selectedHospital.value.id,
        startDate: startDate.value,
        endDate: endDate.value,
      }
    });
    shifts.value = response.data.sort((a, b) => new Date(a.start) - new Date(b.start));
  } catch (error) {
    console.error('Error fetching shifts:', error);
  }
};

const formatDateTime = (dateTime) => {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Zurich'
  };
  return new Intl.DateTimeFormat('de-DE', options).format(new Date(dateTime));
};

const navigateToNewShift = () => {
  router.push('/hospital/new-shift');
};

const toggleCalendar = () => {
  showCalendar.value = !showCalendar.value;
  localStorage.setItem('showCalendar', JSON.stringify(showCalendar.value));
};

const sortBy = (field) => {
  if (field === currentSort.value) {
    currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc';
  } else {
    currentSort.value = field;
    currentSortDir.value = 'asc';
  }
};

const getSortIcon = (field) => {
  if (field === currentSort.value) {
    return {
      'fas fa-sort-up': currentSortDir.value === 'asc',
      'fas fa-sort-down': currentSortDir.value === 'desc'
    };
  }
  return 'fas fa-sort';
};

const calendarEvents = computed(() => {
  const events = shifts.value.map(shift => {
    const event = {
      title: shift.user ? shift.user.name : 'N/A',
      start: shift.start,
      end: shift.end,
      status: shift.status,
      color: shift.status === 'confirmed' ? 'green' : shift.status === 'planned' ? 'orange' : 'gray'
    };
    console.log('Shift event:', event);
    console.log('Original shift data:', shift);
    return event;
  });
  console.log('All calendar events:', events);
  return events;
});


const filteredShifts = computed(() => {
  return shifts.value.filter(shift => {
    const search = searchQuery.value.toLowerCase();
    return (
      shift.status.toLowerCase().includes(search) ||
      (shift.user && shift.user.name.toLowerCase().includes(search)) ||
      shift.types.some(type => type.toLowerCase().includes(search)) ||
      (shift.user ? 'N/A' : '').toLowerCase().includes(search)
    );
  });
});

const sortedShifts = computed(() => {
  return [...filteredShifts.value].sort((a, b) => {
    let aValue = a[currentSort.value];
    let bValue = b[currentSort.value];

    if (currentSort.value === 'userName') {
      aValue = a.user ? a.user.name : '';
      bValue = b.user ? b.user.name : '';
    }

    if (['start', 'end'].includes(currentSort.value)) {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    }

    if (aValue < bValue) return currentSortDir.value === 'asc' ? -1 : 1;
    if (aValue > bValue) return currentSortDir.value === 'asc' ? 1 : -1;
    return 0;
  });
});

onMounted(() => {
  const storedShowCalendar = localStorage.getItem('showCalendar');
  if (storedShowCalendar !== null) {
    showCalendar.value = JSON.parse(storedShowCalendar) === true;
  }
  fetchShifts();

  // Hide hint after 15 seconds
  setTimeout(() => {
    showInitialHint.value = false;
  }, 15000);
});
</script>

<style scoped>
.hospital-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.new-shift-button {
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  box-sizing: border-box;
}

.new-shift-button:hover {
  background-color: #005bb5;
}

.calendar-icon-button {
  position: relative;
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #007aff;
  transition: color 0.3s;
}

.calendar-icon-button:hover {
  color: #005bb5;
}

.calendar-icon-button:focus,
.calendar-icon-button:active {
  outline: none;
  background: none;
}

.calendar-container {
  width: 100%;
  margin-top: 1rem;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: flex-end;
}

.filters label {
  flex: 1 1 100%;
}

.filters input[type="date"],
.filters input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  height: 2.5rem;
  font-size: 1rem;
}

.shift-table-container {
  overflow-x: auto;
  width: 100%;
}

.shift-table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
  margin-top: 1rem;
  table-layout: fixed;
}

th,
td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
  word-wrap: break-word;
  min-width: 150px;
}

th {
  background-color: #f2f2f2;
}

.sortable {
  cursor: pointer;
  user-select: none;
  position: relative;
}

.sortable:hover {
  background-color: #e9e9e9;
}

.sortable i {
  margin-left: 5px;
  font-size: 0.8em;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-dot.planned {
  background-color: orange;
}

.status-dot.confirmed {
  background-color: green;
}

.status-dot.canceled {
  background-color: red;
}

.action-button {
  border: none;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
  margin: 0 2rem;
}

.modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.modal-actions button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actions button:hover {
  background-color: #005bb5;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.disabled-button {
  background-color: #6c757d;
  color: white;
  cursor: not-allowed;
}

.action-button.delete-button {
  background-color: #007aff;
}

.hint-message {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(80, 80, 80, 0.9);
  color: #f5f5f5;
  padding: 0.35rem 0.75rem;
  border-radius: 3px;
  font-size: 0.75rem;
  white-space: nowrap;
  margin-right: 8px;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 14.7s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media (min-width: 600px) {
  .filters {
    flex-wrap: nowrap;
  }

  .filters label {
    flex: 1;
  }
}
</style>
