<template>
  <div class="calendar-container">
    <div class="fc-toolbar">
      <div class="fc-toolbar-title">{{ calendarTitle }}</div>
    </div>
    <FullCalendar :options="calendarOptions" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// Props
const props = defineProps({
  events: {
    type: Array,
    required: true
  }
});

const calendarTitle = ref('');
const calendarOptions = ref({
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  initialView: 'timeGridWeek',
  headerToolbar: {
    left: 'prev,next today',
    center: '',
    right: 'dayGridMonth,timeGridWeek,timeGridDay'
  },
  locale: 'de',
  firstDay: 1,
  titleFormat: {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  },
  slotLabelFormat: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  },
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  },
  views: {
    timeGrid: {
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }
    }
  },
  slotMinTime: '08:00:00',
  buttonText: {
    today: 'Heute',
    month: 'Monat',
    week: 'Woche',
    day: 'Tag'
  },
  editable: true,
  selectable: true,
  selectMirror: true,
  dayMaxEvents: true,
  events: [],
  eventClassNames: function (arg) {
    if (arg.event.extendedProps.status === 'planned') {
      return ['event-planned'];
    } else if (arg.event.extendedProps.status === 'confirmed') {
      return ['event-confirmed'];
    }
    return [];
  },
  datesSet: function (dateInfo) {
    calendarTitle.value = dateInfo.view.title;
  },
  // eventClick(info) {
  //   alert('Event: ' + info.event.title);
  // },
  eventDrop(info) {
    alert('Event: ' + info.event.title + ' was dropped on ' + info.event.start.toISOString());
  },
  eventResize(info) {
    alert('Event: ' + info.event.title + ' end is now ' + info.event.end.toISOString());
  },
  // eventContent: function (arg) {
  //   return {
  //     html: `<div class="fc-event-main-frame">
  //              <div class="fc-event-title-container">
  //                <div class="fc-event-title">${arg.event.title}</div>
  //              </div>
  //            </div>`
  //   }
  // }
});

// Update calendar events when `events` prop changes
watch(
  () => props.events,
  (newEvents) => {
    calendarOptions.value.events = newEvents.map(event => ({
      title: event.title, // Add this to display the title
      start: event.start,
      end: event.end,
      status: event.status,
      backgroundColor: event.status === 'confirmed' ? '#00cc66' : '#ffcc00',
      borderColor: event.status === 'confirmed' ? '#00cc66' : '#ffcc00'
    }));
  },
  { immediate: true }
);
</script>

<style>
.fc-toolbar-title {
  font-size: 1.4em;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.fc .fc-toolbar .fc-button-group .fc-button {
  padding: 0.2rem 0.5rem !important;
  /* Smaller padding for buttons */
  font-size: 0.75rem !important;
  /* Smaller font size for buttons */
}

.fc .fc-toolbar .fc-button {
  padding: 0.2rem 0.5rem !important;
  /* Smaller padding for buttons */
  font-size: 0.75rem !important;
  /* Smaller font size for buttons */
}

.fc-today-button {
  margin-left: 0 !important;
  margin-top: 0.2rem !important;
  max-width: 8rem !important;
}

.event-planned {
  background-color: #ffcc00 !important;
  /* Yellow for planned events */
  border-color: #ffcc00 !important;
}

.event-confirmed {
  background-color: #00cc66 !important;
  /* Green for confirmed events */
  border-color: #00cc66 !important;
}

.calendar-container {
  width: 100%;
}

.fc {
  width: 100%;
}
</style>
