<template>
  <div class="setup-password-container">
    <AppHeader />
    <main>
      <div v-if="loading" class="loading">
        Validating token...
      </div>
      <div v-else-if="error" class="error">
        {{ error }}
      </div>
      <div v-else>
        <h2>Passwort festlegen</h2>
        <form @submit.prevent="handleSubmit" class="setup-form">
          <div class="form-group">
            <label for="password">Neues Passwort</label>
            <input type="password" id="password" v-model="password" required minlength="8" />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Neues Passwort wiederholen</label>
            <input type="password" id="confirmPassword" v-model="confirmPassword" required minlength="8" />
          </div>
          <button type="submit" :disabled="loading">Passwort speichern</button>
        </form>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import api from '../axios';
import AppHeader from '../components/HeaderStd.vue';
import AppFooter from '../components/FooterStd.vue';

const password = ref('');
const confirmPassword = ref('');
const loading = ref(true);
const error = ref(null);
const setupToken = ref(null);

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  setupToken.value = route.query.setupToken;

  if (!setupToken.value) {
    error.value = 'Link ist abgelaufen oder ungültig.';
    loading.value = false;
    return;
  }

  try {
    await api.post('/auth/verify-setup-token', { setupToken: setupToken.value });
    loading.value = false;
  } catch (err) {
    error.value = 'Link ist abgelaufen oder ungültig. Bitte fordern Sie einen neuen Link an.';
    loading.value = false;
  }
});

const handleSubmit = async () => {
  if (password.value !== confirmPassword.value) {
    error.value = 'Die Passwörter stimmen nicht überein';
    return;
  }

  loading.value = true;
  error.value = null;

  try {
    await api.post('/auth/verify-setup-token', { setupToken: setupToken.value });
    await api.post('/auth/setup-password', {
      setupToken: setupToken.value,
      password: password.value,
    });

    router.push('/login');
  } catch (err) {
    if (err.response?.status === 401) {
      error.value = 'Link ist abgelaufen. Bitte fordern Sie einen neuen Link an.';
    } else {
      error.value = 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
input {
  font-size: 16px;
  /* Minimum font size to prevent zoom on iOS */
  padding: 0.75rem;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.setup-password-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

main {
  padding: 1rem;
  flex: 1;
}

.setup-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 2rem auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error {
  color: red;
  margin-bottom: 1rem;
}

.loading {
  text-align: center;
  margin: 2rem 0;
}

input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 0.5rem;
  background-color: #007aff;
  /* Changed from #4CAF50 to #007aff */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #005bb5;
  /* Add hover state matching HospitalNewShift.vue */
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
